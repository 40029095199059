<template>
      <div v-if="classRoom">
        <div style="margin-right: 2%;margin-left: 1%;">
          <!-- <div class="dk_icon" style="width: 80%">
            
          </div> -->
        
     
          <div class="text-right dk_iconsml" v-if="teacherSubjectList.length > 0">
            <button v-if="!isEdit"
              
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="proceedToEdit()"
            
            >
             <span>Edit</span>
            </button>
            <button  
              type="button"
              class="btn btnsml"
              id="svbtn"
              :disabled="!isEdit"
              @click.prevent="updateAssignTeacher"
            
            >
             <span>Save</span>
            </button>
            <button  v-if="isEdit"
                type="button" 
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="cancelEdit()"
              >
                   <span>Cancel</span>
              </button>
          
          </div>
          <!-- <div class="text-right dk_iconsml">
          
            
            <button  
              type="button"
              class="btn btnsml"
              id="svbtn"
              :disabled="!isEdit"
              @click.prevent="updateAssignTeacher"
            
            >
             <span>Save</span>
            </button>
           
          
          </div>
          <div class="text-right dk_iconsml widjetcontent"  v-if="isEdit">
          
            
          
          <button  v-if="isEdit"
                type="button"  style="width: 100%;margin-left: 7px;"
                class="btn btncl clsmdl"
                id="clbtn"
                @click.prevent="cancelEdit()"
              >
                   <span>Cancel</span>
              </button>
        
        </div> -->
        </div>
      
        <div class="widjetcontent">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">Teacher</div>
                        <div class="cell" style="text-align: center;">Subject</div>
                        <!-- <div class="cell">Subject Type</div> -->
                        <div class="cell">Is Admin</div>
                        <!-- <div class="cell">Action</div> -->
                      </div>

                      <!-- <div v-if="teacherSubjectList.length == 0" style="text-align: center;">
                        No Teachers Assigned
                      </div> -->
                      <div
                        class="resrow"
                        v-for="(teacherSubject, index) in teacherSubjectList"
                        :key="index"
                      >
                        <div class="cell" data-title="Teacher" >
                          <select class="form-control form-input" :disabled="!isEdit" id="teacherId" v-model="teacherSubject.teacherId" @change="checkIsAdmin(index)" >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, tIndex) in teacherList"
                            :key="tIndex"
                            :value="item._id"
                          >
                           {{ item.firstName + " " + item.lastName }}
                          </option>
                           
                        </select>
                        </div>
                       
                        <div class="cell" data-title="Subject" style="text-align: center;">
                          {{ getSubjectName(teacherSubject.subjectId) }}
                        </div>
                        <!-- && !teacherSubject.teacherId -->
                         <div class="cell chkbox" data-title="Select" >
                            <label class="custcheckbox" :disabled="!isEdit">
                              <input type="checkbox" :disabled="!isEdit" @change="setIsAdmin(teacherSubject.isAdmin,index)" v-model="teacherSubject.isAdmin"/>
                              <span class="checkmarkchk" :style="!teacherSubject.teacherId ? 'cursor: not-allowed;color: lightgreen;' : 'color: lightgreen;'"></span>
                            </label>
                        </div>

                        <!-- <div class="cell" data-title="Action">
                          <button class="btn round orange tooltipt" :disabled="teacherSubject.isAdmin" @click="removeAssignedObject(index)">
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete</span>
                          </button>
                        </div> -->
                      </div>
                    </div>
                  </div>
    
      
    </div>
    <div class="widjet" v-else style="height: 235px;">
          <div style="padding-top: 92px;" >
            <!-- <span style="margin-left: 42%;" v-if="isLoader">Select Class And ClassRoom</span> -->
            <span style="margin-left: 42%;">Please Select ClassRoom...</span>
          </div>
        </div>
  </template>
  
  <script>
  import "vue-select/dist/vue-select.css";
  import FullCalendar from "@fullcalendar/vue";
  import dayGridPlugin from "@fullcalendar/daygrid";
  import ViService from "@/services/ViService";
  import secureUI from "../../../utils/secureUI";
  import draggable from "vuedraggable";
  
  export default {
    name: "teacher",
    props: {
      classId: {
        type: String,
      },
      classRoom: {
        type: String,
      }
    },
    data() {
      return {
        teacherList: [],
        teacherSubjectList: [],
        subjectList: [],
        isEdit: false
      }
      },
    created() {
      this.initComponent()

     // this.getGradeSetupDetails();

    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
    },
  
    methods: {
      //classRoom
      initComponent(){
        if(this.classRoom){
          this.getTeacherList();
          this.getSubjectList()
          this.getSectionData()
        }
      },
     
      setIsAdmin(value, index){
      if (value) {
        this.teacherSubjectList.forEach((data, i) => {
          if (i != index) {
            data.isAdmin = false;
          } else {
            data.isAdmin = true;
          }
        });
      }
    },
    cancelEdit(){
      this.isEdit = false
      this.getSectionData()
    },
    proceedToEdit(){
      this.isEdit = true
    },
    getSubjectName(id) {
      if (id) {
        if (this.subjectList.length > 0) {
          let details = this.subjectList.filter((x) => x._id == id);
          if (details.length > 0) {
            return details[0].subjectName;
          } else {
            return "";
          }
        } else {
          return "";
        }
      }
     // return this.subjectList.filter(x => x._id == id)[0].subjectName;
    },
      async getSectionData() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
      this.$toasted.error("Please login and do the action");
      this.$router.push('/login');
      } else {
      const response = await ViService.viXGet(
        `/class/getSectionDetails?classId=${this.classRoom}`,
        userData.token
      );
      if (response.isSuccess) {
        let data = secureUI.secureGet(response.data);
        this.teacherSubjectList = data.assignedTeachers || []
       // this.$toasted.success(response.message);
      } else {
        this.$toasted.error(response.message);
          if(response.message == 'Your session has expired, please login'){
              localStorage.removeItem('user');
              this.$router.push('/login');
        }
      }
      }

      this.$bvModal.show("viewSectionPopUp");
      },
      async getTeacherList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/teacher/getUserTeacherList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.teacherList = secureUI.secureGet(response.data).teacherList;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async updateAssignTeacher(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
      //  teacherSubjectList
        let checkAdminUser = this.teacherSubjectList.filter( x => x.isAdmin)
        
        if(checkAdminUser.length > 0){

          let obj = {
          class: this.classId,
          section: this.classRoom,
          assignedTeachers: this.teacherSubjectList,
        };
        const response = await ViService.viXPost(
          "/class/updateAssignTeacher",
          obj,
          userData.token
        );
        this.viLoader = null;
        if (response.isSuccess) {
         // this.$bvModal.hide("assignTechersMdl");
         
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }

        }else{
          
            this.$toasted.error("At least one Teacher must be class admin. Please select IsAdmin");

          
        }

      }
    },
    async getSubjectList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          "/class/getSubjectList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.subjectList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    },
   
  };
  </script>
  